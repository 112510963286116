import moment from 'moment-timezone/moment-timezone';
import find from 'lodash/find';

const timezones = [];
const userTimezone = '';

export const setLocaleForMoment = <T>(locale:T): void => {
  moment.locale(locale);
};



const timezoneName = (): string => {
  if (!userTimezone) {
    return '';
  }
  const currentTimezone: any = find(timezones, { value: userTimezone });
  return currentTimezone.utc[0];
};
const remainingTimeForTimer = (date) => {
  const drawDate = moment(moment(date).utc().format());
  const now = moment(moment().utc().format());
  let timeLeft = parseInt(moment(drawDate).format('x'), null) - parseInt(moment(now).format('x'), null);
  if (timeLeft < 0) { timeLeft = 0; }
  return {
    total: timeLeft,
    days: moment.duration(timeLeft).days(),
    hours: moment.duration(timeLeft).hours(),
    mins: moment.duration(timeLeft).minutes(),
  };
};
const timeWithTimezoneOffset = (time) => {
  if (!timezoneName()) {
    return time;
  }
  return moment(time).tz(timezoneName());
};

const formatDate = (date, dateFormat) => {
  const newDate = moment(date);

  return moment(timeWithTimezoneOffset(newDate)).format(dateFormat);
};
const remainingTimeStr = (date) => {
  const newDate = remainingTimeForTimer(date);
  return `${newDate.days}D ${newDate.hours}H ${newDate.mins}M`;
};
export const transform = (date: any, type: string, dateFormat = ''): any => {
  if (!date) {
    return date;
  }

  switch (type) {
    case 'forTimer':
      return remainingTimeForTimer(date);
    case 'format':
      return formatDate(date, dateFormat);
    case 'dayWithMonth':
      return formatDate(date, 'dddd D MMMM');
    case 'time':
      return formatDate(date, 'h:mma');
    case 'fullDate':
      return formatDate(date, 'dddd, D MMM YYYY');
    case 'forHomePage':
    default:
      return remainingTimeStr(date);
  }
};

export const dateSame = (date1: Date, date2: Date) => {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}
