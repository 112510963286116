import React, { useEffect, useState, useContext, memo } from 'react';
import { getLotteries, getDraws } from '../api';
import ApplicationContext from './ApplicationContext';

const LotteriesContext = React.createContext();

export const LotteriesProvider = LotteriesContext.Provider;
export const LotteriesConsumer = LotteriesContext.Consumer;

export default LotteriesContext;


export const LotteriesState = memo((props) => {
  const [lotteries, setLotteries] = useState(null);
  const [draws, setDraws] = useState(null);
  const { isDesktop } = useContext(ApplicationContext);
  useEffect(() => {
    getLotteries().then((response) => {
      setLotteries(response.data.data);
    }).catch((e) => {
      console.log(e);
      setLotteries([]);
    });
  }, [])
  useEffect(() => {
   isDesktop && getDraws().then((response) => {
       console.log(response);
      setDraws(response.data.data);
    }).catch((e) => {
     console.log(e);
      setDraws([]);
   });
  }, [isDesktop]);
  return (
    <LotteriesProvider value={{
      lotteries,
      draws,
    }}
    >
      {props.children}
    </LotteriesProvider>
  );
});
