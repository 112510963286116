// @flow
import React, {
  useContext, useState, Fragment, memo, useEffect
} from "react"
import './header.scss';
import { Link } from 'gatsby';
import Loadable from 'react-loadable';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import {injectIntl} from "gatsby-plugin-intl"
import shoppingCartIcon from './shopping-cart.svg';
import logo from './logo.svg';
import logoWhite from '../../../assets/icons/logo-white.svg';
import ApplicationContext from '../../../state/ApplicationContext';
import LotteriesContext from '../../../state/LotteriesContext';
import logoSymbol from '../../../assets/icons/logo-symbol.svg';
import LangMenu from '../LangMenu';
import faq from '../../../assets/icons/faq.svg';
import security from '../../../assets/icons/security.svg';
import home from '../../../assets/icons/home.svg';
import results from '../../../assets/icons/results-info.svg';
import { MY_TICKETS, MY_PROFILE, MY_TRANSACTIONS } from '../../../contants/routes';
import HeaderCart from '../HeaderCart';
import defaultAvatar from '../../../assets/icons/default-avatar-vector.svg';
import CartContext from "../../../state/CartContext"

const OtherGame = Loadable({
  loader: () => import('./components/OtherGame'),
  loading: () => <p />,
});

const Accordion = Loadable({
  loader: () => import('../Accordion'),
  loading: () => <p />,
});

const headerPagesLinks = [
  {
    url: '/',
    logo: home,
    pageName: 'Home',
  },
  {
    url: '/results',
    logo: results,
    pageName: 'Results & Info',
  },
  {
    url: '/security',
    logo: security,
    pageName: 'Security',
  },
  {
    url: '/faq',
    logo: faq,
    pageName: 'FAQ',
  },
];


const Header = ({ location, intl: { formatMessage, locale }, userIsChecked,  ...props }) => {
  const [showLeftSidenav, setShowLeftSidenav] = useState(false);
  const { totalPrice } = useContext(CartContext);
  const toggleMenu = () => {
    setShowLeftSidenav(!showLeftSidenav);
  };
  const [pathName, setPathName] = useState('');
  const [slug, setSlug] = useState('/');

  const translate = (str) => (formatMessage({id: str}))
  let isSecurityRoute = false;
  const currentLocale = locale === 'en' ? '': `/${locale}`
  const showDesktopLangMenu = false;
  console.log(location?.pathname)
  let isHome:boolean = false;
  switch(location?.pathname) {
    case '/':
    case '/de/':
    case '/en/':
    case '/es/':
    case '/it/':
    case '/pl/':
    case '/sv/':
    case '/de':
    case '/en':
    case '/es':
    case '/it':
    case '/pl':
    case '/sv':
    case '/security':
    case '/security/':
    case '/de/security':
    case '/en/security':
    case '/es/security':
    case '/it/security':
    case '/pl/security':
    case '/sv/security':
    case '/de/security/':
    case '/en/security/':
    case '/es/security/':
    case '/it/security/':
    case '/pl/security/':
    case '/sv/security/':
      isHome = true;
      break;
    default:
      isHome = false;
  };
  switch(location?.pathname) {
    case '/security':
    case '/security/':
    case '/de/security':
    case '/en/security':
    case '/es/security':
    case '/it/security':
    case '/pl/security':
    case '/sv/security':
    case '/de/security/':
    case '/en/security/':
    case '/es/security/':
    case '/it/security/':
    case '/pl/security/':
    case '/sv/security/':
      isSecurityRoute = true;
      break;
    default:
      isSecurityRoute = false;
  };
  let isDesktop = true;
  const ApplicationState = useContext(ApplicationContext);
  if (ApplicationState) {
    isDesktop = ApplicationState.isDesktop;
  }
  const {
    setShowSignUp, setShowSignIn, user, setUser, isFreeTicketsRoute
  } = ApplicationState;
  const isGuest = !user;
  let lotteries = null;
  const LotteriesState = useContext(LotteriesContext);
  if (LotteriesState) {
    lotteries = LotteriesState.lotteries;
  }
  const firstLinks = lotteries?.slice(0, 3);
  const [secondLinks, setSeconsLinks] = useState(lotteries?.slice(3));

  useEffect(() => {
    setSeconsLinks(lotteries?.slice(3))
  }, [lotteries])
  useEffect(() => {
    if (location.pathname !== pathName) {
      setPathName(location.pathname);
      setShowLeftSidenav(false);
      setSeconsLinks([]);
      if(location.pathname.includes('play')){
       const path = window.location.pathname.split('/');
        setSlug(path[path.length - 1])
      }
      if(!location.pathname.includes('play') && slug){
        setSlug('')
      }
      setTimeout(() => {
        setSeconsLinks(lotteries?.slice(3));
      }, 200)
    }
  }, [location.pathname]);

  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }

  const [currentInViewMobile, setCurrentInViewMobile] = useState(false);
  const [refMobile, inViewMobile] = useInView({
    threshold: 0,
  });
  if (inViewMobile && !currentInViewMobile) {
    setCurrentInViewMobile(true);
  }
  const {
    setCartItems
  } = useContext(CartContext);
  const handleLogout = () => {
    setUser(null);
    setCartItems([])
    localStorage.removeItem('userData');
    localStorage.removeItem('cartItems')
  };
  return (
    <header>
      <div className="header-wrapper">
        <div className="header-mobile">
          <div className={classNames({
            'header-logo': true,
            header: true,
            'menu-open': showLeftSidenav,
            disabled: isHome && !isSecurityRoute
            ,
          })}
          >
            {!isFreeTicketsRoute && <button className="menu-btn" onClick={toggleMenu} />}
            {Boolean(totalPrice) && (
            <Link to={`${currentLocale}/cart`} className="cart-btn">
              <img src={shoppingCartIcon} alt="" />
            </Link>
            )}
            <Link
              to={`${currentLocale ? currentLocale + '/': '/'}`}
              className={classNames('logo', {
                disabled: isHome && !isSecurityRoute,
              })}
            >
              <>
                <img
                  src={logo}
                  key="logo-1"
                  alt="Lotteroo"
                  className={classNames('logo-image', {
                    'logo-home': isHome || isFreeTicketsRoute,
                    showSidenav: showLeftSidenav,
                    showLogo: !isHome && !showLeftSidenav && !isFreeTicketsRoute,
                  })}
                />
                {(isHome || isFreeTicketsRoute) && (
                <img
                  src={logoWhite}
                  key="logo-2"
                  alt=""
                  className={classNames('logo-image-white', {
                    'logo-image-white--active': isHome || isFreeTicketsRoute,
                  })}
                />
                )}
                {showLeftSidenav && (
                <img
                  src={logoSymbol}
                  key="logo-3"
                  alt="Lottero"
                  className={classNames('logo-image-symbol', {
                    'logo-image-symbol--active': showLeftSidenav,
                  })}
                />
                )}
              </>
            </Link>
          </div>
        </div>
        <div className="thanks">Thanks for playing</div>
        <div className={classNames({
          menu: true,
          'overlay-menu': true,
          opened: showLeftSidenav,
        })}
        >
          <div className={classNames('nav', {
            'grey-items': !isHome && !isSecurityRoute,
          })}
          >
            {typeof window !== 'undefined' && userIsChecked ?  <div className={classNames('right', {
              'for-guest': isGuest,
            })}
            >
              {isGuest ? (
                <div className="menu-item auth-menu">
                  {!isFreeTicketsRoute ? (
                    <>
                      <div className="item login" onClick={() => setShowSignIn(true)}>
                        <a>
                          <span className="link-content">
                            {translate('Login')}
                          </span>
                        </a>
                      </div>
                      <div className="item sign-up" onClick={() => setShowSignUp(true)}>
                        <a>
                          <span
                            className="link-content"
                          >
                               {translate('Sign Up')}
                          </span>
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                <div>
                  <div className="menu-item" />
                </div>
              )}

              {!isGuest ? (
                <>
                  {!isDesktop ? (
                    <>
                      <div className="menu-item">
                        <Accordion
                          containerClassName="account-menu"
                          elements={[{
                            title: (<div className="link-item">
                              <div className="image-block"   style={{
                                backgroundImage: user.avatar ? `url(${user.avatar}_40x40.jpg)` : `url(${defaultAvatar})`,
                              }}/>
                              <div className="text-block">
                                {translate('Account')}
                              </div>
                            </div>),
                            children: <ul className="submenu">
                              <li>
                                <Link to={`${currentLocale}${MY_PROFILE}`} className="link-item">
                                  <div className="image-block" />
                                  <div className="text-block">{translate('My Profile')}</div>
                                </Link>
                              </li>
                              <li>
                                <Link to={`${currentLocale}${MY_TICKETS}`} className="link-item">
                                  <div className="image-block" />
                                  <div className="text-block">{translate('My Tickets')}</div>
                                </Link>
                              </li>
                              <li>
                                <Link to={`${currentLocale}${MY_TRANSACTIONS}`} className="link-item">
                                  <div className="image-block" />
                                  <div className="text-block">{translate('My Transactions')}</div>
                                </Link>
                              </li>
                              {/*<li>*/}
                              {/*  <Link to={`${currentLocale}/my-referral`} className="link-item">*/}
                              {/*    <div className="image-block" />*/}
                              {/*    <div className="text-block">{translate('My Referral')}</div>*/}
                              {/*  </Link>*/}
                              {/*</li>*/}
                              <li>
                                <a className="link-item">
                                  <div className="image-block" />
                                  <div className="text-block" onClick={handleLogout}>{translate('Log Out')}</div>
                                </a>
                              </li>
                            </ul>,
                          }]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {!isFreeTicketsRoute ? <HeaderCart />: null}
                      <div className="profile menu-item dropdown-menu">
                        <div
                          className="label"
                          style={{
                            backgroundImage: user.avatar ? `url(${user.avatar}_40x40.jpg)` : defaultAvatar,
                          }}
                        >
                          <div className="name">
                            {`${user.name} ${user.lname}`}
                          </div>
                          <div className="account">{translate('Account')}</div>
                        </div>
                        <ul className="submenu">
                          <li>
                            <Link to={`${currentLocale}${MY_PROFILE}`} className="link-item">
                              <div className="image-block" />
                              <div className="text-block">{translate('My Profile')}</div>
                            </Link>
                          </li>
                          <li>
                            <Link to={`${currentLocale}${MY_TICKETS}`} className="link-item">
                              <div className="image-block" />
                              <div className="text-block">{translate('My Tickets')}</div>
                            </Link>
                          </li>
                          <li>
                            <Link to={`${currentLocale}${MY_TRANSACTIONS}`} className="link-item">
                              <div className="image-block" />
                              <div className="text-block">{translate('My Transactions')}</div>
                            </Link>
                          </li>
                          {/*<li>*/}
                          {/*  <Link to={`${currentLocale}/my-referral`} className="link-item">*/}
                          {/*    <div className="image-block" />*/}
                          {/*    <div className="text-block">{translate('My Referral')}</div>*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li>
                            <a>
                              <div className="image-block" />
                              <div className="text-block" onClick={handleLogout}>{translate('Log Out')}</div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </>
              ) : null}


              <LangMenu
                isDesktop={isDesktop}
                useSuspense={false}
                translate={props}
                showDesktopLangMenu={showDesktopLangMenu}
              />
            </div>: null}


            {!isFreeTicketsRoute && <div className="games-menu">
              {firstLinks?.map((element) => (
                <div className="menu-item" key={element.slug} ref={refMobile}>
                  <Link to={`${currentLocale}/play/${element.slug}`} className={classNames('link-item', {
                    active: element.slug.toLowerCase() === slug.toLowerCase()
                  })}>
                    <div
                      className="image-block"
                      style={{
                         backgroundImage: currentInViewMobile && element.logoImgUrl ? `url(${element.logoImgUrl})`: null,
                       }}
                    />
                    <div className="text-block">{element.lotteryTitle}</div>
                  </Link>
                </div>
              ))}

              <div className="menu-item">
                <Accordion
                  containerClassName="jackpot-menu jackpot-menu-mobile"
                  elements={[{
                    title: (<div className="link-item">
                      <div className="image-block more-jackpots" />
                      <div className="text-block">
                        {translate('More Jackpots')}
                      </div>
                            </div>),
                    children: <OtherGame
                      games={secondLinks}
                      closeMenu={() => setShowLeftSidenav(false)}
                      currentLocale={currentLocale}
                      isDesktop={isDesktop}
                      translate={translate}
                    />,
                  }]}
                />
                <div className="link-item more-games more-games-desktop">
                  <div className="label">
                    <span>
                      {translate('More Jackpots')}
                    </span>
                  </div>
                  {secondLinks?.length ? (
                    <div className="other-games">
                      <OtherGame games={secondLinks} isDesktop={isDesktop}    currentLocale={currentLocale}   translate={translate}/>
                    </div>
) : null}
                </div>
                <div className="overflow" />
              </div>
            </div>}
            {!isFreeTicketsRoute && (
            <div className="pages-menu pages-menu-mobile" ref={ref}>
              {headerPagesLinks?.map((element) => (
                <div className="menu-item" key={element.url}>
                  <Link
                    to={`${currentLocale}${element.url}`}
                    className="link-item"
                  >
                    <div
                       className="image-block"
                       style={{
                        backgroundImage: element.logo ? `url(${element.logo})`: null,
                      }}
                     />
                    <div className="text-block">
                       {translate(element.pageName)}
                     </div>
                  </Link>
                </div>
              ))}
            </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default injectIntl(memo(Header));
