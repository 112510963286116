import React, { useContext } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { transformPrice } from '../../../utils/formattes';
import {injectIntl} from "gatsby-plugin-intl"
import CartContext from '../../../state/CartContext';

const HeaderCart = ({intl: { formatMessage, locale }}) => {
  const { totalPrice } = useContext(CartContext);
  const currentLocale = locale === 'en' ? '': `/${locale}`
  return (
    <div className="cart menu-item">
      <Link
        to={`${currentLocale}/cart`}
        className={classNames('label', {
          empty: !totalPrice,
        })}
      >
        {formatMessage({
          id: 'Cart:'
        })}

        <span className="amount">
          {' '}
          {transformPrice(totalPrice, 'forCart')}
        </span>
        {totalPrice ? <div className="checkout">{formatMessage({
          id: 'Finish checkout'
        })}</div> : (
          <div className="empty">
            {formatMessage({
              id: 'No items'
            })}
          </div>
        )}
      </Link>
    </div>
  );
};
export default injectIntl(HeaderCart);
