import React, { useEffect, useState, useContext, memo } from 'react';
import { getLotteries, getDraws } from '../api';
import ApplicationContext from './ApplicationContext';
import toArray from "lodash/toArray"
import groupBy from "lodash/groupBy"
import map from "lodash/map"

const CartContext = React.createContext();

export const CartProvider = CartContext.Provider;
export const CartConsumer = CartContext.Consumer;

export default CartContext;


export const CartState = memo((props) => {
  const [cartItems, setCartItems] = useState([]);
  const [powerPlayItems, setPowerPlayItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const updateCartItems = () => {
    const cartItemsFromLocalStorage = localStorage.getItem('cartItems');
    if (cartItemsFromLocalStorage) {
      const parsedCartItems = JSON.parse(cartItemsFromLocalStorage);
      const gruppedByGameId = toArray(groupBy(parsedCartItems, (ticket) => ticket.idGame));
      const gruppedByDutaion = gruppedByGameId.map((element) => toArray(groupBy(element, (ticket) => ticket.duration)));
      setCartItems(gruppedByDutaion);
    }
    setIsLoaded(true);
  }
  useEffect(() => {
    updateCartItems()
  }, []);

  const totalPrice = cartItems.reduce((accumulator, currentValue) => {
    const newPrice = currentValue.reduce((priceAccumutator, element, gameIndex) => {
      const { duration } = element[0];
      const newDurations = map(element[0].game.prices, (price: any) => {
        price = {
          ...price,
          durationPrice: (element[0].idGame === 1 && powerPlayItems.includes(gameIndex)? price.priceMultiplier : price.pricePerLine) * (price.quantity >= 8 ? 8 : 1),
        };
        return price;
      });
      const selectedDuration = newDurations.find((durationItem) => durationItem.quantity === duration);
      return (selectedDuration.durationPrice * element.length) * (selectedDuration.quantity >= 8 ? selectedDuration.quantity / 8 : selectedDuration.quantity) + priceAccumutator;
    }, 0);
    return newPrice + accumulator;
  }, 0);
  return (
    <CartProvider value={{
      cartItems,
      setCartItems,
      totalPrice,
      powerPlayItems,
      setPowerPlayItems,
      updateCartItems,
      isLoaded
    }}
    >
      {props.children}
    </CartProvider>
  );
});
