// @flow
import React, { useState, useEffect } from 'react';
import './footer.scss';
import { Link } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { injectIntl } from 'gatsby-plugin-intl';
import logoWhite from '../../../assets/icons/logo-white.svg';
import geoTrust from '../../../assets/images/GeoTrust.png';
import awareTrust from '../../../assets/images/gambleAware.png';
import visa from './visa.svg';
import maestro from './maestro.svg';
import mastercard from './mastercard.svg';
import astropay from './astropay.svg';

const trustLinks = [
  {
    url: 'https://www.geotrust.com/',
    imageSrc: geoTrust,
    alt: 'GeoTrust',
  },
  {
    url: 'https://www.begambleaware.org',
    imageSrc: awareTrust,
    alt: 'Aware Trust Link',
  },
];
const Footer = ({ location, intl: { formatMessage, locale } }) => {
  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }
  const currentLocale = locale === 'en' ? '': `/${locale}`
  let isHome:boolean = false;
  switch(location?.pathname) {
    case '/':
    case '/de/':
    case '/en/':
    case '/es/':
    case '/it/':
    case '/pl/':
    case '/sv/':
    case '/de':
    case '/en':
    case '/es':
    case '/it':
    case '/pl':
    case '/sv':
      isHome = true;
      break;
    default:
      isHome = false;
  };
  return (
    <footer className="footer" ref={ref}>
      <div className="nav page-content">
        <div className="most-played">
          <div className="title">
            {formatMessage({ id: 'Most Played Games' })}
          </div>
          <ul>
            <li>
              <Link to={`${currentLocale}/play/powerball`}>
                US Powerball
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/play/euro-jackpot`}>
                Euro Jackpot
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/play/mega-millions`}>
                MegaMillions
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/play/euro-millions`}>
                EuroMillions
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/play/el-gordo`}>
                El Gordo
              </Link>
            </li>
          </ul>
        </div>
        <div className="banking">
          <div className="title">
            {formatMessage({ id: 'Banking' })}
          </div>
          <div className="card-container">
            <span className="item">
              <img src={currentInView ? visa : undefined} alt="" />
            </span>
            <span className="item">
              <img src={currentInView ? mastercard : undefined} alt="" />
            </span>
            <span className="item">
              <img src={currentInView ? maestro : undefined} alt="" />
            </span>
            <span className="item">
              <img src={currentInView ? visa : undefined} alt="" />
            </span>
            <span className="item">
              <img src={currentInView ? astropay : undefined} alt="" />
            </span>
          </div>
        </div>
        <div className="about">
          <div className="title">
            {formatMessage({ id: 'About Lotteroo' })}
          </div>
          <ul>
            <li>
              <Link to={`${currentLocale}/about-us`}>
                {formatMessage({ id: 'Who we are?' })}
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/contact-us`}>
                {formatMessage({ id: 'Need help? We’re here!' })}
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/responsible-gaming`}>
                {formatMessage({ id: 'Keep it fun! Responsible gaming' })}
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/terms`}>
                {formatMessage({ id: 'Terms & Conditions' })}
              </Link>
            </li>
            <li>
              <Link to={`${currentLocale}/privacy`}>
                {formatMessage({ id: 'Privacy Policy' })}
              </Link>
            </li>
          </ul>
        </div>
        <div className="get-started">
          <div className="text-title">
            {' '}
            {formatMessage({ id: "We're building the future of lottery" })}
          </div>
          <p>
            {formatMessage({ id: 'GetStartedText' })}
          </p>
          <Link to={`${currentLocale}/play/powerball`}>
            <button>
              {formatMessage({ id: 'Play Now' })}
            </button>
          </Link>
        </div>
      </div>
      <div className="bottom page-content">
        <div className="logo-wrap">
          <div className="logo">
            <Link
              to={currentLocale ? currentLocale + '/': '/'}
              className={classNames({
                disabled: isHome,
              })}
            >
              <img src={currentInView ? logoWhite : undefined} alt="Lotteroo logo" />
            </Link>
          </div>
          <div className="geotrust">
            {trustLinks.map((element) => (
              <a href={element.url} className="geotrust-logo" target="_blank" key={element.alt}>
                <img src={currentInView ? element.imageSrc : undefined} alt={element.alt} />
              </a>
            ))}
          </div>
        </div>
        <div className="disclaimer">
          <p>
            {' '}
            {formatMessage({ id: 'Disclaimer1' })}
            {' '}
          </p>

          <p>
            {formatMessage({ id: 'Disclaimer2' })}
            {' '}
          </p>

          <p>
            { formatMessage({ id: 'Disclaimer3' })}
          </p>

          <p>
            {' '}
            { formatMessage({ id: 'Disclaimer4' })}
          </p>

          <p>
            { formatMessage({ id: 'Disclaimer5' })}
            {' '}
            <br />
            <a target="_blank" href="https://www.begambleaware.org/">https://www.begambleaware.org/</a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};

export default injectIntl(Footer);
