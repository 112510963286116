import axios from "axios"

const generateParamsString = (params) => {
  const EUC = encodeURIComponent
  return (Object.keys(params)
    .map((key) => {
      const paramsKey = EUC(params[key])
      if (paramsKey !== "undefined") {
        return `${EUC(key)}=${paramsKey}`
      }
      return null
    }).filter((element) => !!element)
    .join("&"))
}


const PROD_API_URL = "https://api.lotteroo.com/v1/"
const API_URL = "https://new_api_lotteroo.abz.dev/v1/"

const apiInstance = axios.create({
  baseURL: process.env.GATSBY_API_URL ? process.env.GATSBY_API_URL : "https://new_api_lotteroo.abz.dev/v1/"
})

export const setIterceptors = (setShowGrowl, setGrowlMessage, formatMessage) => {
  apiInstance.interceptors.response.use((response) => response,
    (error) => {
      setShowGrowl(true)
      setGrowlMessage(formatMessage({ id: error.response.data.message }))
      if (error.response.data.message === "Your request was made with invalid credentials.") {
        localStorage.removeItem("userData")
      }
      return Promise.reject(error)
    })
}

export const getLotteries = () => apiInstance.get("/lotteries")
export const getDraws = () => apiInstance.get("/draws?per_page=5&page=1")

export const getDrawForResults = ({
                                    page, dateFrom, dateTo, lottery, vendorLimit, per_page
                                  }) => {
  const params = generateParamsString({
    page, dateFrom, dateTo, lottery, vendorLimit, per_page
  })

  return apiInstance.get(`/draws?${params}`)
}
export const checkCountry = () => apiInstance.get("/users/check-country")
export const getConfig = () => apiInstance.get("/config")

export const userSignUp = (body) => apiInstance.post("/users", body)

export const userSignIn = (body) => apiInstance.post("/users/login", body)

export const forgotPassword = (body) => apiInstance.post("/users/password-reset-request", body)

export const resetPassword = (body, resetPassword) => {
  if (resetPassword) {
    const userToken = localStorage.getItem("userToken")

    if (!userToken) return null
    return apiInstance.post("/users/password-reset-confirm", body, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    })
  }
  return apiInstance.post("/users/password-reset-confirm", body)
}

export const editUserSettings = (body, userId) => {
  const userToken = localStorage.getItem("userToken")
  if (!userToken) return null
  return apiInstance.post(`/users/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}

export const editUserSettingsCheckboxes = (body) => {
  const userToken = localStorage.getItem("userToken")
  if (!userToken) return null
  return apiInstance.put("/users/settings", body, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}

export const getUserData = () => {
  const userData = localStorage.getItem("userData")
  let userId = ""
  if (userData) {
    userId = (JSON.parse(userData)).id
  }

  const userToken = localStorage.getItem("userToken")
  if (!userToken || !userId) return null
  return apiInstance.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}


export const getTickets = (perPage, page) => {
  const userToken = localStorage.getItem("userToken")
  if (!userToken) return null
  return apiInstance.get(`/tickets?per_page=${perPage}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}

export const updateTicket = (id) => {
  const userToken = localStorage.getItem("userToken")
  if (!userToken) return null
  return apiInstance.put(`/tickets/${id}`, { count: 0 }, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}

export const getTicketsCalendar = () => {
  const userToken = localStorage.getItem("userToken")
  if (!userToken) return null
  return apiInstance.get("/tickets/calendar", {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })
}
