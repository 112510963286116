import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { navigate } from 'gatsby';
import { changeLocale, injectIntl } from 'gatsby-plugin-intl';
import ApplicationContext from '../../../state/ApplicationContext';

const languages = [
  {
    flag: '',
    flagHref: '//uploads.bablic.com/flags/24/es.png',
    hidden: false,
    href: '/es/',
    key: 'es',
    name: 'Español',
  },
  {
    flag: null,
    flagHref: '//uploads.bablic.com/flags/24/it.png',
    hidden: false,
    href: '/it/',
    key: 'it',
    name: 'Italiano',
  },
  {
    flag: null,
    flagHref: '//uploads.bablic.com/flags/24/de.png',
    hidden: false,
    href: '/de/',
    key: 'de',
    name: 'Deutsch',
  },
  {
    flag: null,
    flagHref: '//uploads.bablic.com/flags/24/pl.png',
    hidden: false,
    href: '/pl/',
    key: 'pl',
    name: 'Polski',
  },
  {
    flag: null,
    flagHref: '//uploads.bablic.com/flags/24/sv.png',
    hidden: false,
    href: '/sv/',
    key: 'sv',
    name: 'Svenska',
  },
  {
    flag: null,
    flagHref: '//uploads.bablic.com/flags/24/en.png',
    hidden: false,
    href: '/',
    key: 'en',
    name: 'English',
  },
];

const LangMenu = ({
  isDesktop, ...props
}) => {
  const [showDesktopLangMenu, setShowDesktopLangMenu] = useState(false);
  const [currentLanguageIndex, setCurrentLanguage] = useState(5);
  const toggleLangMenu = () => {
    setShowDesktopLangMenu(!showDesktopLangMenu);
  };

  const { setLocale } = useContext(ApplicationContext);

  useEffect(() => {
    setCurrentLanguage(languages.findIndex((element, index) => {
      if (element.key === props.intl.locale) return true;
    }));
  }, [props.intl.locale]);

  const setCurrentLanguageIndex = (index) => (event) => {
    event.preventDefault();
    setCurrentLanguage(index);
    setLocale(languages[index].key);
    if (languages[index].key === 'en') {
      const pathname = window.location.pathname.split('/');

      if (pathname[1] === 'es' || pathname[1] === 'it' || pathname[1] === 'de' || pathname[1] === 'pl' || pathname[1] === 'sv') {
        navigate(window.location.pathname.replace(`${pathname[1]}/`, ''));
      }
    } else {
      changeLocale(languages[index].key);
    }
    toggleLangMenu();
  };
  const currentLanguage = languages[currentLanguageIndex];
  const [currentInView, setCurrentInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });
  if (inView && !currentInView) {
    setCurrentInView(true);
  }
  return (
    <div className="lang-switcher">
      <div className={classNames('dropdown-menu menu-item', {
        active: showDesktopLangMenu,
        'language-dropdown': isDesktop,
        'language-dropdown-mobile': !isDesktop,
      })}
      >
        <div className="flag label" onClick={toggleLangMenu}>
          <img src={currentLanguage.flagHref} alt="" />
          <span>{ currentLanguage.name }</span>
        </div>
        <ul>
          {languages.map((element, index) => (
            <li ref={ref} key={element.name}>
              <a href="" onClick={setCurrentLanguageIndex(index)}>
                <img src={currentInView ? element.flagHref : undefined} alt={element.name} />
                {element.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default injectIntl(LangMenu);
