export const getParameterByName = (name: string, url = '') => {
  let currentURL = url;
  if (!currentURL) {
    currentURL = window.location.href;
  }

  const currentName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${currentName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentURL);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
