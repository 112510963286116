import React, { useState, useEffect, memo } from 'react';
import Loadable from 'react-loadable';
// import { IntlProvider } from 'gatsby-plugin-intl';
import { ApplicationProvider } from '../state/ApplicationContext';
import { LotteriesState } from '../state/LotteriesContext';
import { CartState } from '../state/CartContext';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import '../assets/styles/main.scss';
import withTranslate from '../hoc/withTranslate';
import { getParameterByName } from '../utils/urlUtils';
import { setIterceptors, getUserData } from '../api';
import {Helmet} from "react-helmet"
import {injectIntl} from 'gatsby-plugin-intl';
import {setLocaleForMoment} from "../utils/dateFormatted"
import mediaLogo from '../assets/images/lotteroo-media-logo.png';
// import
// from '../locales';

const SignUp = Loadable({
  loader: () => import('../components/Modals/Auth/SignUp'),
  loading: () => <p />,
});
const SignIn = Loadable({
  loader: () => import('../components/Modals/Auth/SignIn'),
  loading: () => <p />,
});
const ForgotPassword = Loadable({
  loader: () => import('../components/Modals/Auth/ForgotPassword'),
  loading: () => <p />,
});
const ResetPassword = Loadable({
  loader: () => import('../components/Modals/Auth/ResetPassword'),
  loading: () => <p />,
});
const Growl = Loadable({
  loader: () => import('../components/common/Growl'),
  loading: () => <p />,
});

const Layout = ({ children, intl,  ...props }) => {
  const [isDesktop, setDesktop] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [user, setUser] = useState(null);
  const [resetToken, setResetToken] = useState('');
  const [userIsChecked, setUserIsChecked] = useState(false);
  const [locale, setLocale] = useState('en');
  const [pathName, setPathName] = useState('');
  // const messages = translations[locale];
  useEffect(() => {
    if (props.location.pathname !== pathName) {
      setPathName(props.location.pathname);
      window.scrollTo(0, 0);
    }
  }, [props.location.pathname]);

  const [resetPassword, setResetPassword] = useState(false);

  const [showGrowl, setShowGrowl] = useState(false);
  const [growlMessage, setGrowlMessage] = useState(false);
  const [growlSuccess, setGrowlSuccess] = useState(false);
  const [isFreeTicketsRoute, setFreeTicketsRoute] = useState(false);

  if (props.location.pathname.includes('free-tickets') && !isFreeTicketsRoute) {
    setFreeTicketsRoute(true);
    if (typeof window !== 'undefined') {
      document.querySelector('html').classList.add('free-tickets-html');
    }
  }

  if (!props.location.pathname.includes('free-tickets') && isFreeTicketsRoute) {
    setFreeTicketsRoute(false);
    if (typeof window !== 'undefined') {
      document.querySelector('html').classList.remove('free-tickets-html');
    }
  }

  useEffect(() => {
    setIterceptors(setShowGrowl, setGrowlMessage, intl.formatMessage);
  }, [intl.locale]);
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setUser(JSON.parse(userData));
      try {
        getUserData().then((response) => {
          setUser(response.data);
          localStorage.setItem('userData', JSON.stringify(response.data));
        }).catch((e) => {
          setUser(null);
          localStorage.removeItem('userData');
          if (getParameterByName('login')) {
            setShowSignIn(true);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
    setUserIsChecked(true)
    if (!userData && getParameterByName('login')) {
      setShowSignIn(true);
    }

    const isWindows = /Win/.test(navigator.platform);
    if (isWindows) {
      document.querySelector('html').classList.add('windows');
    }
    if (navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)) {
      document.querySelector('html').classList.add('safari');
    }
    setResetToken(getParameterByName('reset-token'));
    const handleResize = () => {
      if (isDesktop && window.innerWidth < 1140) setDesktop(false);
      if (!isDesktop && window.innerWidth >= 1140) setDesktop(true);
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize)
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize)
    };
  }, [isDesktop]);

  const handleGrowl = (show: React.SetStateAction<boolean>, message: React.SetStateAction<boolean>, success: boolean) => {
    setShowGrowl(show);
    setGrowlMessage(message);
    setGrowlSuccess(Boolean(success));
  };

  setLocaleForMoment<string>(intl.locale);


  const applicationState = {
    isDesktop,
    setShowSignUp,
    setShowSignIn,
    setUser,
    user,
    setShowForgot,
    handleGrowl,
    setLocale,
    setResetPassword,
    setFreeTicketsRoute,
    userIsChecked,
    isFreeTicketsRoute,
  };

  const handleCloseReset = () => {
    setResetToken(null);
    setResetPassword(false);
  };
  return (
    // <IntlProvider locale={locale} messages={messages}>
      <ApplicationProvider value={applicationState}>
        <LotteriesState>
          <CartState>
            <div className="wrapper">
              <Helmet>
                <html lang={intl.locale} />
                <meta property={'og:title'} content={'Lotteroo'}/>
                <meta property={'og:description'} content={intl.formatMessage({id: 'Play the world’s biggest Jackpots'})}/>
                <meta property={'og:image'} content={mediaLogo}/>
                <meta name={'twitter:card'} content={'free-tickets'}/>
                <meta name={'HandheldFriendly'} content={'True'}/>
                {!process.env.GATSBY_API_URL ?   <meta name="robots" content={'none'}  />: null}
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black" />
              </Helmet>
              <Header useSuspense={false} location={props.location} userIsChecked={userIsChecked} />
              <div className="content">
                {showGrowl ? <Growl timeout={15000} handleGrowl={handleGrowl} growlMessage={growlMessage} success={growlSuccess} /> : null }
                {showSignUp ? <SignUp onClose={() => setShowSignUp(false)} handleGrowl={handleGrowl} /> : null }
                {showSignIn ? <SignIn onClose={() => setShowSignIn(false)} /> : null }
                {showForgot ? <ForgotPassword onClose={() => setShowForgot(false)} /> : null }
                {resetToken || resetPassword ? <ResetPassword onClose={handleCloseReset} token={resetToken} resetPasswordValue={resetPassword} /> : null}
                {children}
              </div>
              <Footer location={props.location} />
            </div>
          </CartState>
        </LotteriesState>
      </ApplicationProvider>
    // </IntlProvider>
  );
};

export default withTranslate(memo(injectIntl(Layout)));
