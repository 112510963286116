// @flow
const currency = '€';

const formatNumbersForHotGame = (price: number): string => {
  switch (true) {
    case price / 1000000000 >= 1:
      return `${(price / 1000000000).toFixed(1)} Billion`;
    case price / 1000000 >= 1:
      return `${(price / 1000000).toFixed(1)} Million`;
    case price / 1000 >= 1:
      return `${(price / 1000).toFixed(1)} Thousand`;
    default:
      return Math.round(price).toString();
  }
};

const formatNumberWithSpaces = (value: number) => value.toLocaleString('en-US').replace(/,/g, ' ');

const formatBigNumber = (value: number, decimals = 1) => {
  let div: number;
  let str: string;
  if (value >= 1000000000) {
    div = 1000000000;
    str = 'B';
  } else if (value >= 1000000) {
    div = 1000000;
    str = 'M';
  } else if (value >= 1000) {
    div = 1000;
    str = 'K';
  } else {
    return value.toString();
  }
  const mult = Math.pow(10, decimals);
  const out = (Math.round(value / (div / mult)) / mult) + str;

  return out;
};

const formatNumberWithCommas = (value: number) => {
  const valueStr: string = value.toFixed(2);
  const parts = valueStr.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const transformPrice = (price: any, type: string): any => {
  if (!price && price !== 0) {
    return price;
  }

  const newPrice = price / 100;

  switch (type) {
    // case 'amount':
    //   return this.formatBigNumber(price);
    case 'amountWithCurrency':
      return currency + formatBigNumber(newPrice);
    case 'amountWithSpaces':
      return currency + formatNumberWithSpaces(newPrice);
    case 'amountForHotGame':
      return currency + formatNumbersForHotGame(newPrice).replace('.0', '');
    case 'amountWithCommas':
      return currency + formatNumberWithCommas(newPrice);
    case 'priceWithCurrency':
      return currency + newPrice;
    case 'forCart':
      return currency + newPrice.toFixed(2);
    default:
      return newPrice;
  }
};
